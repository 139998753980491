import React from 'react';
import DatesFilter from './DatesFilter';
import CategoriesFilter from './CategoriesFilter';
import styled from 'styled-components';
import Session from './Session';
import { isPast, applyTimezone, isSameDay, now } from '../../helpers/date';
import Date from '../../components/shared/agenda/Date';
import SwipeContainer from '../../components/swipe/SwipeContainer';

const Container = styled.div`
  margin-top: ${props => props.componentFixedTopHeight + 15 + 'px'};
`;

const Now = styled.div`
  font-size: 1.2rem;
  color: #fff;
  background-color: #d0021b;
  border-radius: 4px;
  padding: 5px 10px;
  margin-top: 20px;
  position: relative;
  display: inline-block;
`;

const Line = styled.div`
  position: fixed;
  z-index: 0;
  top: 0;
  bottom: 0;
  border-left: 1px solid ${props => props.theme.mainColor};
  margin-left: 30px;
`;

class Agenda extends React.Component {
  componentDidMount() {
    this.props.fetchSessions(true);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.agenda.id !== prevProps.agenda.id ||
      this.props.currentDate !== prevProps.currentDate
    ) {
      this.props.fetchSessions(true);
    }
  }

  onSwipeEnd = event => {
    const pos = this.props.agenda.dates.indexOf(this.props.currentDate);

    if (event.x > 150 && this.props.agenda.dates[pos - 1]) {
      this.props.setDate(this.props.agenda.dates[pos - 1]);
    } else if (event.x < -150 && this.props.agenda.dates[pos + 1]) {
      this.props.setDate(this.props.agenda.dates[pos + 1]);
    }
  };

  render() {
    const {
      t,
      theme,
      history,
      headerHeight,
      agenda,
      timezone,
      sessions,
      currentDate,
      setDate,
      matchParams,
      isCategoriesFiltersOpen,
      categories,
      categoriesFilters,
      filterCategories,
      filterAllCategories,
      setComponentFixedTopHeight,
      componentFixedTopHeight,
      sessionsIds,
      categoriesIds
    } = this.props;

    let nowIsRendered = false;
    let renderNow = false;

    const { appId, eventId, agendaId } = matchParams;

    return (
      <SwipeContainer onSwipeEnd={this.onSwipeEnd}>
        <React.Fragment>
          <CategoriesFilter
            {...{
              t,
              theme,
              headerHeight,
              categories,
              categoriesIds,
              categoriesFilters,
              filterCategories,
              filterAllCategories,
              isCategoriesFiltersOpen
            }}
          />

          {agenda.dates.length > 1 ? (
            <DatesFilter
              {...{
                t,
                theme,
                headerHeight,
                setDate,
                currentDate,
                agenda,
                setComponentFixedTopHeight
              }}
            />
          ) : (
            <Date
              headerHeight={headerHeight}
              date={currentDate}
              timezone={timezone}
              setComponentFixedTopHeight={setComponentFixedTopHeight}
            />
          )}

          <Container componentFixedTopHeight={componentFixedTopHeight}>
            <Line />
            {sessionsIds.map((key, index) => {
              const session = sessions[key];
              const sessionUrl = `/apps/${appId}/events/${eventId}/agendas/${agendaId}/sessions/${session.id}`;
              renderNow =
                !isPast(applyTimezone(session.end_date, timezone), 0, 'minute') &&
                !nowIsRendered &&
                isSameDay(now(), session.end_date);
              if (renderNow) {
                nowIsRendered = true;
              }
              return (
                <React.Fragment key={session.id}>
                  {renderNow ? <Now>{t('now')}</Now> : null}
                  <Session {...{ session, theme, t, timezone, sessionUrl, history, matchParams }} />
                </React.Fragment>
              );
            })}
          </Container>
        </React.Fragment>
      </SwipeContainer>
    );
  }
}

export default Agenda;
