import React from 'react';
import FlexParent from '../../components/shared/flex/FlexParent';
import FlexItem from '../../components/shared/flex/FlexItem';
import styled from 'styled-components';
import Card from '../../components/shared/card/Card';
import Star from '../../components/shared/fav/Star';
import { isPast, getTime, applyTimezone } from '../../helpers/date';

const Times = styled(FlexItem)`
  text-align: center;
  margin-right: 15px;
  font-size: 1.5rem;
`;

const TimeStart = styled.div`
  color: ${props => props.theme.darkGrey};
  padding-bottom: 4px;
`;

const TimeEnd = styled.div`
  color: ${props => props.theme.shadowGrey};
`;

const CategoryCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin: 5px;
`;

const SessionName = styled.div`
  font-size: 1.5rem;
  padding: 3px 0 3px 0;
`;

const SessionLocation = styled.div`
  padding: 0 10px 0 0;
  font-size: 1.2rem;
  color: ${props => props.theme.shadowGrey};
`;

const IsPast = styled.div`
  position: absolute;
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => (props.sessionIsPast ? 'rgba(255, 255, 255, 0.5)' : 'transparent')};
`;

class Session extends React.Component {
  nowIsRendered = false;

  onClickSession = () => {
    const { sessionUrl, history, matchParams } = this.props;
    const { agendaId } = matchParams;
    window.localStorage.setItem(`scrollY_agenda_${agendaId}`, window.pageYOffset);
    history.push(sessionUrl);
  };

  render() {
    this.nowIsRendered = false;
    const { session, timezone, matchParams, theme } = this.props;
    const sessionIsPast = isPast(applyTimezone(session.end_date, timezone), 0, 'minute');
    return (
      <Card
        margin={`15px 0 15px ${session.parent_id ? '50px' : ''}`}
        padding={'10px'}
        onClick={this.onClickSession}
      >
        <IsPast sessionIsPast={sessionIsPast} />
        <FlexParent alignItems={'center'}>
          <Times shrink={'0'}>
            <TimeStart>{getTime(applyTimezone(session.start_date, timezone))}</TimeStart>
            <TimeEnd>{getTime(applyTimezone(session.end_date, timezone))}</TimeEnd>
          </Times>
          <FlexItem grow={1}>
            <SessionName>{session.name}</SessionName>
            <FlexParent alignItems={'center'}>
              {session.location ? <SessionLocation>{session.location}</SessionLocation> : null}
              {session.categories.map(category => {
                return (
                  <CategoryCircle key={category.id} style={{ backgroundColor: category.color }} />
                );
              })}
            </FlexParent>
          </FlexItem>

          <FlexItem alignSelf={'start'}>
            <Star
              {...{ theme }}
              model={session}
              type={'agenda_session'}
              matchParams={matchParams}
            />
          </FlexItem>
        </FlexParent>
      </Card>
    );
  }
}

export default Session;
