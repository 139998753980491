import styled from 'styled-components';
import React from 'react';
import Modal from '../modal/Modal';
import Button from './Button';
import HtmlContent from './HtmlContent';
import { connect } from 'react-redux';
import { fetchDisclaimer } from '../../actions/api';
import Loader from './Loader';

const CGV = styled.div`
  text-align: center;
  padding: 0 0 10px;
  font-size: 1.1rem;
`;

const DisclaimerLink = styled.a`
  color: black;
  text-decoration-color: ${props => props.theme.mainColor};
`;

const ModalContent = styled.div`
  text-align: initial;
  padding: 15px 0 10px;
  font-size: 1.2rem;
`;

const ModalDisclaimer = props => {
  const { open, onClose, t, disclaimer } = props;
  return (
    <Modal onClose={onClose} open={open}>
      <ModalContent>
        {disclaimer ? <HtmlContent>{disclaimer}</HtmlContent> : <Loader />}
      </ModalContent>
      <Button btntype={'primary'} onClick={onClose}>
        {t('btn_ok')}
      </Button>
    </Modal>
  );
};

class Disclaimer extends React.Component {
  state = {
    modalIsOpen: false
  };

  onClickCGV = event => {
    event.preventDefault();
    const { appId, eventId } = this.props.matchParams;
    if (!this.state.disclaimer) {
      this.props.fetchDisclaimer({
        app_id: appId,
        event_id: eventId,
        done: response => {
          this.setState({
            disclaimer: response.data
          });
        }
      });
    }
    this.openModal();
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  render() {
    const { t, label } = this.props;
    return (
      <CGV>
        <DisclaimerLink href="." onClick={this.onClickCGV}>
          {label}
        </DisclaimerLink>
        <ModalDisclaimer
          disclaimer={this.state.disclaimer}
          open={this.state.modalIsOpen}
          t={t}
          onClose={this.closeModal}
        />
      </CGV>
    );
  }
}

const mapDispatchToProps = {
  fetchDisclaimer: fetchDisclaimer
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Disclaimer);
